import React from 'react';

import Layout from '../components/Layout';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import BlockContent from '@sanity/block-content-to-react';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "portrait" }) {
        id
        childImageSharp {
          fluid(maxHeight: 700, maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sanityPage(slug: { current: { eq: "landing" } }) {
        title
        subTitle
        _rawBody
        enumerations {
          id
          keywords
          text
          title
          color
          linktext
          link
          image {
            asset {
              fluid(maxHeight: 700, maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityPost(sort: { fields: [publishedAt], order: DESC }, limit: 4) {
        edges {
          node {
            id
            publishedAt(locale: "de", formatString: "DD.MMMM.YYYY")
            title
            _rawSubText
            slug {
              current
            }
            mainImage {
              asset {
                fluid(maxHeight: 200, maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            author {
              name
            }
          }
        }
      }
    }
  `);

  const site = data.sanityPage;
  const enumerations = data.sanityPage.enumerations;
  const posts = data.allSanityPost.edges;

  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{site.title}</h2>
            <h3 style={{ fontSize: '1em', fontWeight: 300 }}>
              {site.subTitle}
            </h3>
          </div>
        </header>
      </section>
      <section id="wrapper">
        <div className="wrapper">
          <div className="inner">
            {site._rawBody && <BlockContent blocks={site._rawBody} />}
          </div>
        </div>
      </section>

      <section id="wrapper">
        {enumerations.map((e, index) => (
          <section
            key={e.id}
            id={e.title}
            className={`wrapper spotlight ${index % 2 ? '' : 'alt'} style${
              e.color
            }`}
          >
            <div className="inner">
              <Img className="image" fluid={e.image.asset.fluid} alt={e.name} />

              <div className="content">
                <h2 className="major">{e.title}</h2>
                <p>{e.text}</p>
                {e.link && (
                  <Link to={`../${e.link}`} className="special">
                    {e.linktext}
                  </Link>
                )}
              </div>
            </div>
            <div style={{ display: 'none' }}>{e.keywords}</div>
          </section>
        ))}

        <section id="four" className="wrapper style1">
          <div className="inner">
            <h2 className="major">Blogbeiträge</h2>
            <section className="features">
              {posts.map(post => (
                <article key={post.node.id}>
                  <Link to={`../${post.node.slug.current}`} className="image">
                    {post.node.mainImage && (
                      <Img fluid={post.node.mainImage.asset.fluid} alt="" />
                    )}
                  </Link>
                  <h3 className="major">{post.node.title}</h3>
                  <span style={{ fontSize: 18 }}>
                    <b>{`${post.node.author.name} - ${post.node.publishedAt}`}</b>
                  </span>

                  <BlockContent blocks={post.node._rawSubText} />
                  <Link to={`../${post.node.slug.current}`} className="special">
                    Mehr dazu
                  </Link>
                </article>
              ))}
            </section>
            <ul className="actions">
              <li>
                <Link to="/blog" className="button">
                  Alle Blogbeiträge
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default IndexPage;
